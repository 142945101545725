import { useAddProfileRoleMutation } from "@/graphql/types";
import gql from "graphql-tag";

gql`
  mutation addProfileRole($input: AddProfileRoleInputType!) {
    addProfileRole(input: $input)
  }
`;

export default function () {
  const mutation = useAddProfileRoleMutation({});
  function call(profileId: string, roleId: string) {
    return mutation.mutate({
      input: {
        profileId: profileId,
        roleId: roleId,
      },
    });
  }

  return {
    ...mutation,
    call,
  };
}
